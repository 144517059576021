import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import AdditionalInsuranceImg from '../../../images/additional_insurance.jpg'

const paginaServizio = {
  titolo: 'Additional insurance',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: AdditionalInsuranceImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'Se vuoi estendere la copertura assicurativa prevista per legge, con GLS puoi scegliere fra tre diverse opzioni aggiuntive:',
        },
        {
          id: 2,
          testo:
            'ASSICURAZIONE INTEGRATIVA AD VALOREM: polizza assicurativa personalizzata in base alle tue esigenze, in relazione al tipo di merce, valore e massimale richiesto;',
        },
        {
          id: 3,
          testo:
            'ASSICURAZIONE 10/10: polizza che prevede 10 Euro come importo massimo risarcibile al Kilogrammo, con 10 chilogrammi di franchigia;',
        },
        {
          id: 4,
          testo:
            "ASSICURAZIONE ALL-IN: la tua merce è garantita a valore, fino ad un massimo di € 1.500, senza scoperti né franchigie, in caso di eventuali danni, furti o rapine che la stessa dovesse subire in fase di trasporto. Un servizio accessorio dedicato a tutta quella clientela che ha esigenze sempre più specifiche, come ad esempio l'acquisto di prodotti on-line, e richiede servizi ad alto valore aggiunto.",
        },
      ],
      pesoDimensioni: null,
      vantaggi: [
        {
          id: 1,
          testo:
            'Maggiore sicurezza per le spedizioni standard di alto valore rispetto alle normative di legge',
        },
        {
          id: 2,
          testo: 'Abbinabile a tutti i prodotti',
        },
        {
          id: 3,
          testo:
            'Prenota il servizio tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'National Express',
          linkUrl: '/servizi/national-express',
        },
        {
          id: 2,
          nome: 'Safe Plus',
          linkUrl: '/servizi/safe-plus',
        },
        {
          id: 3,
          nome: 'Parcel',
          linkUrl: '/servizi/parcel',
        },
        {
          id: 4,
          nome: 'Express',
          linkUrl: '/servizi/express',
        },
        {
          id: 5,
          nome: 'Logistics',
          linkUrl: '/servizi/logistics',
        },
      ],
      servizi: null,
    },
  ],
}

const AdditionalInsurance = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default AdditionalInsurance
